import { NavLink } from '@remix-run/react';
import type { NavLinkProps } from '@remix-run/react';

import { Trans } from 'react-i18next';
import { cva } from 'cva';
import { useContext } from 'react';
import classNames from 'clsx';

import { NavigationMenuContext } from './NavigationMenuContext';

interface LinkModel {
  path: string;
  label: string;
}

const NavigationMenuItem: React.FCC<{
  link: LinkModel;
  disabled?: boolean;
  className?: string;
  end?: NavLinkProps['end'];
  prefetch?: NavLinkProps['prefetch'];
}> = ({ link, end, disabled, prefetch, ...props }) => {
  const label = link.label;

  const menuProps = useContext(NavigationMenuContext);

  return (
    <NavLink
      prefetch={prefetch ?? 'intent'}
      end={end}
      className={({ isActive }) => {
        const className = getNavigationMenuItemClassBuilder()({
          active: isActive,
          ...menuProps,
        });

        return classNames(className, props.className ?? ``);
      }}
      aria-disabled={disabled}
      to={disabled ? '' : link.path}
    >
      <Trans i18nKey={label} defaults={label} />
    </NavLink>
  );
};

export default NavigationMenuItem;

function getNavigationMenuItemClassBuilder() {
  return cva(
    [
      `p-1 lg:px-2.5 flex items-center justify-center font-medium lg:justify-start rounded-md text-sm transition colors transform active:translate-y-[2px]`,
    ],
    {
      compoundVariants: [
        // not active - shared
        {
          active: false,
          className: `active:text-current text-gray-600 dark:text-gray-200
        hover:text-gray-900 dark:hover:text-white`,
        },
        // active - shared
        {
          active: true,
          className: `text-gray-900 dark:text-white`,
        },
        // active - pill
        {
          active: true,
          pill: true,
          className: `bg-gray-50 text-gray-600 dark:bg-primary-300/10`,
        },
        // not active - pill
        {
          active: false,
          pill: true,
          className: `hover:bg-gray-50 active:bg-gray-100 text-gray-500 dark:text-gray-300 dark:hover:bg-background dark:active:bg-dark-900/90`,
        },
        // not active - bordered
        {
          active: false,
          bordered: true,
          className: `hover:bg-gray-50 active:bg-gray-100 dark:active:bg-dark-800 dark:hover:bg-dark/90 transition-colors rounded-lg border-transparent`,
        },
        // active - bordered
        {
          active: true,
          bordered: true,
          className: `top-[0.4rem] border-b-[0.25rem] rounded-none border-primary bg-transparent pb-[0.8rem] text-primary-700 dark:text-white`,
        },
        // active - secondary
        {
          active: true,
          secondary: true,
          className: `bg-transparent font-semibold`,
        },
      ],
      variants: {
        active: {
          true: ``,
        },
        pill: {
          true: `py-2`,
        },
        bordered: {
          true: `relative h-10`,
        },
        secondary: {
          true: ``,
        },
      },
    },
  );
}
